export const SET_PROJECT_NAME = "SET_PROJECT_NAME";
export const SET_PROJECT_DESC = "SET_PROJECT_DESC";
export const SET_TEAM_SIZE = "SET_TEAM_SIZE";
export const SET_PROJECT_DUR = "SET_PROJECT_DUR";
export const SET_PROJECT_STATUS = "SET_PROJECT_STATUS";
export const ADD_PROJECT_CATEG = "ADD_PROJECT_CATEG";
export const REMOVE_PROJECT_CATEG = "REMOVE_PROJECT_CATEG";
export const ADD_ROLE = "ADD_ROLE";
export const ADD_SKILL = "ADD_SKILL";
export const ADD_RESPONSIBILITY = "ADD_RESPONSIBILITY";
export const ADD_LOCATION = "ADD_LOCATION";
export const CLEAR_DATA = "CLEAR_DATA";
export const SET_ERROR_MSG = "SET_ERROR_MSG";
export const INCREASE_STEP = "INCREASE_STEP";
export const DECREASE_STEP = "DECREASE_STEP";