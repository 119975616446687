export const SET_FULL_NAME = "SET_FULL_NAME";
export const SET_EMAIL = "SET_EMAIL";
export const SET_PASSWORD = "SET_PASSWORD";
export const SET_YEAR = "SET_YEAR";
export const SET_MAJOR = "SET_MAJOR";
export const SET_CAMPUS = "SET_CAMPUS";
export const ADD_INTEREST = "ADD_INTEREST";
export const REMOVE_INTEREST = "REMOVE_INTEREST"
export const ADD_SKILL = "ADD_SKILL";
export const REMOVE_SKILL = "REMOVE_SKILL"
export const ADD_RESUME = "ADD_RESUME"
export const INCREASE_STEP = "INCREASE_STEP"
export const DECREASE_STEP = "DECREASE_STEP"
export const SET_STEP = "SET_STEP";
export const SET_ERROR_MSG = "SET_ERROR_MSG";
export const CLEAR_DATA = "CLEAR_DATA";